import React from 'react';
import Slider from 'react-slick';
import { TestimonialOneParams } from '../../utils/script';

import './_TestimonialOne.scss';

let data = [
    {
        name: "Amber Lorenzini",
        details: "My 6-year-old began requesting to play hooky instead of going to school. As a teacher turned stay at home mom, I've navigated classroom chaos and listened to enough school bus sagas to fill a storybook. This was the green light to start homeschooling. <br><br>That was when I decided to welcome a small group atmosphere that would be a safe place for my daughter to giggle with new besties while receiving personalized learning. I sought out to create classes that are engaging and apply The Whole Child Approach to Education.<br><br>This led to Little House of Hugs being born, a place where every learning journey is as special as the child embarking on it.<br><br><b>'Embrace Learning in the Warmth of Little House of Hugs'</b>",
        designation: "Founder of Little House of Hugs",
        image: "client-01.png"
    }
];

const TestimonialOne = ( props ) => {
    const classes = `testimonial-activation testimonial-style-1 edu-slick-button slick-button-left ${props.className ? props.className : '' }`;
    data = props.item ? data.slice( 0, props.item ) : data;
    return (
        <Slider 
            className={ classes }
            { ...TestimonialOneParams }
        >
            { data.map( ( item, i ) => (
                <div className="single-testimonial" key={ i }>
                    <div className="inner">
                        {/* { props.quoteIcon !== 'disable' && 
                            <div className="quote-sign">
                                <img src="/images/testimonial/testimonial-01/quote.png" alt="Quote Images" />
                            </div>
                        } */}
                        <p className="description" dangerouslySetInnerHTML={{ __html: item.details }}></p>
                        <div className="client-information">
                            <div className="thumb">
                                <img src={`${process.env.PUBLIC_URL}/images/instructor/amber-lorenzini.jpg`} alt="Amber Lorenzini" />
                            </div>
                            <div className="info">
                                <h5 className="title">{ item.name }</h5>
                                <span className="designation">{ item.designation }</span>
                            </div>
                        </div>
                    </div>
                </div>
            ) ) }
        </Slider>
    );
}
export default TestimonialOne;