import React from 'react';
import SEO from '../../../common/SEO';
import Layout from '../../../common/Layout';
import BreadcrumbOne from '../../../common/breadcrumb/BreadcrumbOne';
import AboutNine from '../../../components/about/AboutNine';
import AboutUsTwoService from '../../../components/about-us-two/AboutUsTwoService';
import AwardOne from '../../../components/award/AwardOne';
import AboutUsTwoInstructor from '../../../components/about-us-two/AboutUsTwoInstructor';
import AboutUsTwoCountDown from '../../../components/about-us-two/AboutUsTwoCountDown';
import TestimonialSectionOne from '../../../components/testimonial-section/TestimonialSectionOne';

import About from './About';

import "./_WesWorld.scss";

const WesWorld = () => {
    return (
        <>
            <SEO title="WesWorld" />
            <Layout className='wesworld-wrapper' includeFooter={false} whiteLogo={true}>
            <About />
            </Layout>
        </>
    )
}

export default WesWorld;