import React from 'react'
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

const PrivacyPolicy = () => {
    return (
        <>
            <SEO title="Privacy Policy" />
            <Layout>
                <BreadcrumbOne 
                    title="Privacy Policy"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Privacy Policy"
                />
                <div className="edu-privacy-policy-area edu-privacy-policy bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="content">
                                <h3 className="title">Privacy Policy</h3>
                                
                                <p><strong>Effective Date:</strong> 01/02/2024</p>

                                <p>Welcome to Little House of Hugs! Your privacy is important to us. This Privacy Policy outlines how we collect, use, and protect your personal information when you use our website at littlehouseofhugs.com ("we," "us," or "our").</p>

                                <h4>1. Information We Collect</h4>
                                <p>We may collect personal information such as your name, email address, and other relevant details when you use our website. This information is collected with your consent or as required by law.</p>

                                <h4>2. How We Use Your Information</h4>
                                <p>We may use the collected information to provide and improve our services, personalize your experience, and communicate with you about promotions, updates, and other relevant information. We do not sell your personal information to third parties.</p>

                                <h4>3. Data Sharing</h4>
                                <p>We may share your information with third-party service providers to facilitate our services, such as payment processing, analytics, and marketing. These third parties are obligated to protect your data and are prohibited from using it for any purpose other than providing services to Little House of Hugs.</p>

                                <h4>4. Cookies</h4>
                                <p>We use cookies to enhance your user experience. You can control cookies through your browser settings, but disabling cookies may limit certain features of our website.</p>

                                <h4>5. Data Security</h4>
                                <p>We implement reasonable security measures to protect your data from unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>

                                <h4>6. Your Choices</h4>
                                <p>You have the right to access, correct, or delete your personal information. You can also opt-out of receiving promotional communications from us.</p>

                                <h4>7. Changes to the Privacy Policy</h4>
                                <p>We reserve the right to modify or update this Privacy Policy at any time. We will notify you of any changes by posting the updated policy on our website.</p>

                                <h4>8. Contact Us</h4>
                                <p>If you have any questions or concerns about our Privacy Policy, please contact us at <a href="mailto:privacy@littlehouseofhugs.com">privacy@littlehouseofhugs.com</a>.</p>

                                <p>Thank you for trusting Little House of Hugs with your information!</p>

                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default PrivacyPolicy;