// StripeHandler.js

// Import the 'auth' object from firebase.js
import { auth, getUserEmail } from "../firebase";

// Import 'payments' from firebase.js
import { payments } from "../firebase";

export default class StripeHandler {
  constructor() {}

  // Fetch the list of products from your backend
  async getProducts() {
    try {
      console.log("Fetching products...");

      const productsResponse = await fetch(process.env.REACT_APP_API_URL+"/products");

      if (!productsResponse.ok) {
        throw new Error(`Failed to fetch products: ${productsResponse.statusText}`);
      }

      const products = await productsResponse.json();
      return products;
    } catch (error) {
      console.error("Error fetching products:", error.message);
      throw error; // Rethrow the error to be caught by the caller if needed
    }
  }

  // Fetch the products and initiate the checkout process
  async checkout(priceIds, selectedStudentsMap) {
    try {
      if(priceIds.length === 0){
        return {"status": "error", "message": "No classes chosen. Please add classes and try again."}
      }
      // console.log("priceIds", priceIds);

      // console.log("Starting checkout...");

      const userEmail = await getUserEmail();
      console.log("userEmail", userEmail);

      const checkoutData = {
        checkout_items: priceIds,
        email: userEmail,
        classes: selectedStudentsMap,
      };
      console.log("checkoutData", checkoutData);

      const checkoutResponse = await fetch(process.env.REACT_APP_API_URL + "/create-checkout-session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(checkoutData),
      });

      const checkoutResponseJSON = await checkoutResponse.json();

      if (checkoutResponseJSON.status === "error") {
        if(checkoutResponseJSON.detail){
          return {"status": "error", "message": checkoutResponseJSON.detail}
        } else {
          return {"status": "error", "message": checkoutResponseJSON.statusText}
        }
      }

      const data = await checkoutResponseJSON;
      // console.log("Data", data);
      const sessionUrl = data.session_url;
      return {"status": "success", "session_url": sessionUrl};

    } catch (error) {
      console.error("Error during checkout:", error.message);
      // Handle errors as needed
      return {"status": "error", "message": error.message}
    }
  }
}
