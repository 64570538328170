import React from 'react';
import SEO from '../../common/SEO';
import HeaderTwo from '../../common/header/HeaderTwo';
import BannerTwo from '../../components/banner/BannerTwo';
import HomeTwoService from '../../components/home-two/HomeTwoService';
import AboutFour from '../../components/about/AboutFour';
import HomeTwoCourses from '../../components/home-two/HomeTwoCourses';
import HomeTwoEvents from '../../components/home-two/HomeTwoEvents';
import HomeTwoTestimonial from '../../components/home-two/HomeTwoTestimonial';
import AboutOne from '../../components/about/AboutOne';
import CallToActionOne from '../../components/cta/CallToActionOne';
import HomeTwoBlog from '../../components/home-two/HomeTwoBlog';
import FooterOne from '../../common/footer/FooterOne';
import HomeFiveCategories from '../../components/home-five/HomeFiveCategories';

import About from '../about/LittleHouseOfHugs/About';
import AboutLittleHouseOfHugs from '../../components/about/AboutLittleHouseOfHugs';

const HomeV1 = () => {
    return (
        <>
            <SEO title="Home" />
            <HeaderTwo styles="header-transparent header-style-2" />
            <BannerTwo />
            <About />
            <HomeTwoCourses />
            <AboutLittleHouseOfHugs />
    
            <FooterOne />

        </>
    )
}

export default HomeV1;
