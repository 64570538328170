import React, { useState } from 'react';
import { FaPlay } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import SectionTitle from '../sectionTitle/SectionTitle';

import "./_TutoringService.scss";

const VideoPopup = ({ videoLink, startAt, onClose }) => (
  <div className="video-popup active" onClick={onClose}>
    <div className="video-popup-inner">
      <button className="video-popup-close" onClick={onClose}><i className="icon-cross"></i></button>
      <div className="iframe-video">
        <iframe src={`https://www.youtube.com/embed/${videoLink}?start=${startAt}`} allow="autoplay; fullscreen" allowFullScreen title="Tutoring"></iframe>
      </div>
    </div>
  </div>
);

const TutoringService = () => {
  const [videoPopupVisible, setVideoPopupVisible] = useState(false);
  const videoLink = 'smscgY5WEnU';
  const startAt = 32;

  const toggleVideoPopup = () => {
    setVideoPopupVisible(!videoPopupVisible);
  };

  const closeVideoPopup = (e) => {
    // Check if the click is outside the iframe-video
    if (e.target.classList.contains('video-popup')) {
      setVideoPopupVisible(false);
    }
  };

  return (
    <>
      <div className="consulting-video edu-video-area edu-section-gap video-style-2">
        <div className="container eduvibe-animated-shape">
          <div className="row">
            <div className="col-lg-8">
              <div className="pr--75 pr_lg--30 pr_md--0 pr_sm--0">
                <div className="thumbnail video-popup-wrapper" onClick={toggleVideoPopup}>
                  <img className="radius-small" src={`/images/littlehouseofhugs/services/tutoring-video-1.jpg`} alt="Tutoring PopUp Thumb" />
                  <button className="video-play-btn with-animation position-to-top video-popup-activation color-secondary size-80" onClick={toggleVideoPopup}>
                    <span><FaPlay className="play-icon" /></span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="content mt_md--40 mt_sm--40">
                <SectionTitle
                  classes="text-start"
                  slogan="TUTORING"
                  title="High Impact Tutoring"
                />
                <p className="description mt--20 mb--30 mt_md--20 mt_sm--20">At Little House of Hugs, we blend nurturing relationships with data-driven, personalized instruction. Our approach not only fills learning gaps but also ensures equal educational access, building bridges for all. <br></br> <br></br> Specializing in K-5 literacy, we integrate lessons from Wilson Fundations and Orton-Gillingham, unlocking the joy of reading for every child. Lessons are not just learned - they are loved. <br></br> <br></br><b> 1 Hour Tutoring Session - $75 <br></br> Travel fee required for locations 10+ miles from Fuquay Varina, NC.</b></p>
                <Link className="edu-btn" to="https://book.stripe.com/test_4gw8yBauV0wseNG144">Schedule Tutoring<i className="icon-arrow-right-line-right"></i></Link>
              </div>
            </div>
          </div>
        </div>

        <div className="side-shape-image d-lg-block d-none">
          <img src="/images/videopopup/video-infinite-rotate.png" alt="Shape Images" />
        </div>
      </div>

      {videoPopupVisible && <VideoPopup videoLink={videoLink} startAt={startAt} onClose={closeVideoPopup} />}
    </>
  );
};

export default TutoringService;
