import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';
import { FaPlay } from 'react-icons/fa';
import CourseTypeFilter from '../../components/course/CourseTypeFilter';

const HomeTwoCourses = () => {
    const [toggler, setToggler] = useState( false );
    return (
        <>
            <div className="edu-course-area eduvibe-home-two-course course-three-wrapper bg-color-white">
                <div className="container eduvibe-animated-shape">
                    <CourseTypeFilter />

                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="shape-image shape-image-1">
                            <img src="/images/shapes/shape-04-02.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-2">
                            <img src="/images/shapes/shape-03-06.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-3">
                            <img src="/images/shapes/shape-04-03.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-4">
                            <img src="/images/shapes/shape-07-01.png" alt="Shape Thumb" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeTwoCourses;