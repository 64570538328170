import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

import './_calendar.scss';

const Calendar = ({ events: initialEvents }) => {
  const calendarRef = useRef(null);
  const dispatch = useDispatch();
  const selectedEvents = useSelector((state) => state.selectedEvents);

  const eventClicked = (info) => {
    console.log('event clicked', info);

    // info.el.style.backgroundColor = 'yellow';

    // if (info.event.title) {
    //   const clickedEventTitle = info.event.title;
    //   const calendarApi = calendarRef.current.getApi();
    //   const allEvents = calendarApi.getEvents();

    //   console.log('all Events', allEvents);

    //   const selectedEvents = allEvents.filter(
    //     (event) => event.title === clickedEventTitle
    //   );

    //   console.log('selected Events', selectedEvents);

    //   selectedEvents.forEach((event) => {
    //     const eventElement = calendarApi.getEventById(event.id);
    //     if (eventElement) {
    //       eventElement.setProp('backgroundColor', 'yellow');
    //     }
    //   });

    //   // Dispatch action to update Redux store with selected events
    //   dispatch({ type: 'SELECTED_EVENTS_UPDATED', payload: selectedEvents });
    //}
  };

  useEffect(() => {
    const calendar = calendarRef.current.getApi();

    // You can customize the calendar options here
    calendar.setOption('plugins', [dayGridPlugin]);
    calendar.setOption('initialView', 'dayGridMonth');

    return () => {
      calendar.destroy();
    };
  }, []);

  return (
    <div className="calendar">
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        events={initialEvents}
        eventClick={eventClicked}
      />
    </div>
  );
};

export default Calendar;
