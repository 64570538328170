// Routes.js
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import HomeTwo from './pages/homepages/HomeTwo';
import CourseOne from './pages/class/CourseOne';
import CourseDetails from './pages/detailspages/CourseDetails';

import CourseFilterSidebar from './pages/class/CourseFilterSidebar';
import AboutUsOne from './pages/innerpages/AboutUsOne';
import AboutUsTwo from './pages/innerpages/AboutUsTwo';
import AboutUsThree from './pages/innerpages/AboutUsThree';
import BlogGridOne from './pages/blog/BlogGridOne';
import BlogGridTwo from './pages/blog/BlogGridTwo';
import BlogGridThree from './pages/blog/BlogGridThree';
import BlogCarousel from './pages/blog/BlogCarousel';
import BlogLoadMore from './pages/blog/BlogLoadMore';
import BlogStandard from './pages/blog/BlogStandard';
import BlogGridRightSidebar from './pages/blog/BlogGridRightSidebar';
import BlogGridLeftSidebar from './pages/blog/BlogGridLeftSidebar';
import BlogDetailsOne from './pages/detailspages/BlogDetailsOne';
import BlogDetailsTwo from './pages/detailspages/BlogDetailsTwo';
import BlogDetailsThree from './pages/detailspages/BlogDetailsThree';
import CategoryArchive from './pages/archive/CategoryArchive';
import TagArchive from './pages/archive/TagArchive';
import AuthorArchive from './pages/archive/AuthorArchive';
import ComingSoon from './pages/innerpages/ComingSoon';
import ContactMe from './pages/innerpages/ContactMe';
import ContactUs from './pages/innerpages/ContactUs';
import CourseTwo from './pages/class/CourseTwo';
import CourseThree from './pages/class/CourseThree';
import CourseFour from './pages/class/CourseFour';
import CourseFive from './pages/class/CourseFive';
import CourseFilterOne from './pages/class/CourseFilterOne';
import CourseFilterTwo from './pages/class/CourseFilterTwo';
import CourseCarousel from './pages/class/CourseCarousel';
import CourseLoadMore from './pages/class/CourseLoadMore';
import CourseCategoryArchive from './pages/archive/CourseCategoryArchive';
import CourseDetailsTwo from './pages/detailspages/CourseDetailsTwo';
import EventGrid from './pages/innerpages/EventGrid';
import EventList from './pages/innerpages/EventList';
import EventLoadMore from './pages/innerpages/EventLoadMore';
import EventCarousel from './pages/innerpages/EventCarousel';
import EventDetails from './pages/detailspages/EventDetails';
import Faq from './pages/innerpages/Faq';
import GalleryGrid from './pages/innerpages/GalleryGrid';
import GalleryMasonry from './pages/innerpages/GalleryMasonry';
import GalleryLoadMore from './pages/innerpages/GalleryLoadMore';
import HomeOne from './pages/homepages/HomeOne';
import HomeThree from './pages/homepages/HomeThree';
import HomeFour from './pages/homepages/HomeFour';
import HomeFive from './pages/homepages/HomeFive';
import InstructorPageOne from './pages/innerpages/InstructorPageOne';
import InstructorPageTwo from './pages/innerpages/InstructorPageTwo';
import InstructorPageThree from './pages/innerpages/InstructorPageThree';
import LandingDemo from './pages/LandingDemo';
import Login from './pages/innerpages/Login';
import Register from './pages/innerpages/Register';

import Pricing from './pages/innerpages/Pricing';
import PrivacyPolicy from './pages/innerpages/PrivacyPolicy';
import PurchaseGuide from './pages/innerpages/PurchaseGuide';
import Testimonial from './pages/innerpages/Testimonial';
import InstructorDetails from './pages/detailspages/InstructorDetails';
import Error from './pages/innerpages/Error';

import LittleHouseOfHugs from './pages/about/LittleHouseOfHugs/LittleHouseOfHugs';
import WesWorld from './pages/about/WesWorld/WesWorld';

import './assets/scss/style.scss';
import Terms from './pages/innerpages/Terms';
import EmailLinkSent from './pages/auth/EmailLinkSent';
import EmailLinkReturn from './pages/auth/EmailLinkReturn';
import ResetPassword from './pages/innerpages/ResetPassword';
import MyAccount from './pages/account/MyAccount';
import Logout from './pages/auth/Logout';
import MyClasses from './pages/account/MyClasses';
import Schedule from './pages/class/Schedule';
import Enroll from './pages/account/Enroll';
import EnrollThankYou from './pages/account/EnrollThankYou';

import { useStripeHandler } from './services/StripeHandler';
import Services from './pages/services/StudentAssessmentService';
import StudentAssessmentService from './pages/services/StudentAssessmentService';
import TutoringServicePage from './pages/services/TutoringServicePage';
import HomeV1 from './pages/homepages/HomeV1';

const PrivateRoute = ({ element }) => {
  const user = useSelector(state => state.user, false);
  if (user && user.uid) {
    return element;
  } else {
    return <Navigate to="/login" />;
  }
};

const EnrollRoute = ({ element }) => {
  const user = useSelector(state => state.user, false);
  // console.log("user", user);
  if (user && user.uid) {
    return element;
  } else {
    return <Navigate to="/register?next=/enroll" />;
  }
};

const AppRoutes = () => (
  <Routes>
    <Route exact path='/' element={<HomeV1/>}/>

    <Route exact path={`${process.env.PUBLIC_URL + '/enroll/'}`} element={<EnrollRoute element={<Enroll />} />}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/enroll/thank-you'}`} element={<EnrollRoute element={<EnrollThankYou />} />}/>
    <Route path="/enrollment" element={<Navigate to="/enroll" />} />
    

    <Route exact path={`${process.env.PUBLIC_URL + '/classes/'}`} element={<CourseOne/>}/>
    {/* <Route exact path={`${process.env.PUBLIC_URL + '/classes/schedule'}`} element={<Schedule/>}/> */}

    <Route exact path={`${process.env.PUBLIC_URL + '/classes/:class_id'}`} element={<CourseDetails/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/classes/:class_id/details'}`} element={<CourseDetails/>}/>

    <Route exact path={`${process.env.PUBLIC_URL + '/instructor-details/:slug'}`} element={<InstructorDetails/>}/>

    <Route exact path={`${process.env.PUBLIC_URL + '/services'}`} element={<Services/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/services/assessment'}`} element={<StudentAssessmentService/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/services/assessments'}`} element={<StudentAssessmentService/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/services/tutoring'}`} element={<TutoringServicePage/>}/>


    <Route exact path={`${process.env.PUBLIC_URL + '/terms'}`} element={<Terms/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/privacy-policy'}`} element={<PrivacyPolicy/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/faq'}`} element={<Faq/>}/>

    <Route exact path={`${process.env.PUBLIC_URL + '/about'}`} element={<LittleHouseOfHugs/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/about/littlehouseofhugs'}`} element={<LittleHouseOfHugs/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/about/teachers'}`} element={<AboutUsOne/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/about/wesworld'}`} element={<WesWorld/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/contact'}`} element={<ContactUs/>}/>

    <Route exact path={`${process.env.PUBLIC_URL + '/testimonials'}`} element={<Testimonial/>}/>
  
    <Route exact path={`${process.env.PUBLIC_URL + '/auth/reset'}`} element={<ResetPassword/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/auth/email-link-sent'}`} element={<EmailLinkSent/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/auth/email-link-return'}`} element={<EmailLinkReturn/>}/>

    <Route exact path={`${process.env.PUBLIC_URL + '/login'}`} element={<Login/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/register'}`} element={<Register />}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/logout'}`} element={<Logout/>}/>

    <Route exact path={`${process.env.PUBLIC_URL + '/account/'}`}   element={<PrivateRoute element={<MyAccount />} />}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/account/enroll'}`}  element={<EnrollRoute element={<Enroll />} />}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/account/classes'}`}   element={<PrivateRoute element={<MyClasses />} />}/>

{/* 
    <Route exact path={`${process.env.PUBLIC_URL + '/home-1'}`} element={<HomeOne/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/home-2'}`} element={<HomeTwo/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/home-3'}`} element={<HomeThree/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/home-4'}`} element={<HomeFour/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/home-5'}`} element={<HomeFive/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/about-us-1'}`} element={<AboutUsOne/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/about-us-2'}`} element={<AboutUsTwo/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/about-us-3'}`} element={<AboutUsThree/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/coming-soon'}`} element={<ComingSoon/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/contact-me'}`} element={<ContactMe/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/contact-us'}`} element={<ContactUs/>}/>

    <Route exact path={`${process.env.PUBLIC_URL + '/class-1'}`} element={<CourseOne/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/class-2'}`} element={<CourseTwo/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/class-3'}`} element={<CourseThree/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/class-4'}`} element={<CourseFour/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/class-5'}`} element={<CourseFive/>}/>

    <Route exact path={`${process.env.PUBLIC_URL + '/class-filter-1'}`} element={<CourseFilterOne/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/class-filter-2'}`} element={<CourseFilterTwo/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/class-carousel'}`} element={<CourseCarousel/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/class-load-more'}`} element={<CourseLoadMore/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/class-category/:slug'}`} element={<CourseCategoryArchive/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/class-details-two/:id'}`} element={<CourseDetailsTwo/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/class-filter-sidebar'}`} element={<CourseFilterSidebar />}/>

    <Route exact path={`${process.env.PUBLIC_URL + '/event-grid'}`} element={<EventGrid/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/event-list'}`} element={<EventList/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/event-load-more'}`} element={<EventLoadMore/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/event-carousel'}`} element={<EventCarousel/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/event-details/:id'}`} element={<EventDetails/>}/>


    <Route exact path={`${process.env.PUBLIC_URL + '/gallery-grid'}`} element={<GalleryGrid/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/gallery-masonry'}`} element={<GalleryMasonry/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/gallery-load-more'}`} element={<GalleryLoadMore/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/instructor-one'}`} element={<InstructorPageOne/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/instructor-two'}`} element={<InstructorPageTwo/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/instructor-three'}`} element={<InstructorPageThree/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/pricing'}`} element={<Pricing/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/purchase-guide'}`} element={<PurchaseGuide/>}/>

    <Route exact path={`${process.env.PUBLIC_URL + '/blog-grid-1'}`} element={<BlogGridOne/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/blog-grid-2'}`} element={<BlogGridTwo/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/blog-grid-3'}`} element={<BlogGridThree/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/blog-carousel'}`} element={<BlogCarousel/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/blog-load-more'}`} element={<BlogLoadMore/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/blog-standard'}`} element={<BlogStandard/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/blog-grid-right-sidebar'}`} element={<BlogGridRightSidebar/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/blog-grid-left-sidebar'}`} element={<BlogGridLeftSidebar/>}/>


    <Route exact path={`${process.env.PUBLIC_URL + '/blog-details/:id'}`} element={<BlogDetailsOne/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/blog-details-left-sidebar/:id'}`} element={<BlogDetailsTwo/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/blog-details-right-sidebar/:id'}`} element={<BlogDetailsThree/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/category/:slug'}`} element={<CategoryArchive/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/tag/:slug'}`} element={<TagArchive/>}/>
    <Route exact path={`${process.env.PUBLIC_URL + '/author/:slug'}`} element={<AuthorArchive/>}/> */}
    <Route path='*' element={<Error/>}/>
  </Routes>
);

export default AppRoutes;