export const parseEmojis = (text) => {
    let parsedText = text.replace(/:rocket:/g, '🚀');
    parsedText = parsedText.replace(/:male-technologist:/g, '👨‍💻');
    parsedText = parsedText.replace(/:female-technologist:/g, '👩‍💻');
  
    // Add <br> tags for line breaks
    const textWithLineBreaks = parsedText.replace(/\n/g, '<br>');
  
    return textWithLineBreaks;
};
