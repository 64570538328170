import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';
import { FaPlay } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitle from '../sectionTitle/SectionTitle';

const AssessmentService = () => {
    const [toggler, setToggler] = useState(false);
    const videoLink = ['https://www.youtube.com/watch?v=pNje3bWz7V8'];
    return (
        <>
            <div className="consulting-video edu-video-area edu-section-gap video-style-2">
                <div className="container eduvibe-animated-shape">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="pr--75 pr_lg--30 pr_md--0 pr_sm--0">
                                <div className="thumbnail video-popup-wrapper">
                                    <img className="radius-small" src={`/images/littlehouseofhugs/services/assessment-1.jpg`} alt="Video PopUp Thumb" />
                                    {/* <button className="video-play-btn with-animation position-to-top video-popup-activation color-secondary size-80" onClick={() => setToggler(!toggler)}>
                                        <span><FaPlay className="play-icon" /></span>
                                    </button> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="content mt_md--40 mt_sm--40">
                                <SectionTitle
                                    classes="text-start"
                                    slogan="Personalized Assessments"
                                    title="👩‍🎓 Rooted in the<br> Science of Reading"
                                />
                                <p className="description mt--40 mb--40 mt_md--20 mt_sm--20">Is reading a puzzle for your kiddo?<br/> Unsure how to help?<br></br><br/>Enter the superhero of tools: Independent Reading Level Assessment® (IRLA®). It's my trusty sidekick to identifyreading strengths and challenges. Together, we'll set clear goals and map out a path to reading success and personal growth. </p>
                                <Link className="edu-btn" to="https://book.stripe.com/test_00g4il32tcfa5d63cd">Schedule Assessment<i className="icon-arrow-right-line-right"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="side-shape-image d-lg-block d-none">
                    <img src="/images/videopopup/video-infinite-rotate.png" alt="Shape Images" />
                </div>
            </div>

            <FsLightbox
                toggler={toggler}
                sources={videoLink}
                maxYoutubeVideoDimensions={{ width: 900, height: 550 }}
            />
        </>
    )
}

export default AssessmentService;