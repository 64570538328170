// Layout.js

import React from 'react';
import HeaderOne from './header/HeaderOne';
import FooterOne from './footer/FooterOne';
import HeaderTwo from './header/HeaderTwo';

const Layout = ({ className, children, includeFooter = true, whiteLogo = false }) => {
    return (
        <>
            <main className={`main-wrapper ${className}`}>
                <HeaderTwo styles="header-transparent header-style-2" whiteLogo={whiteLogo} />
                {children}
                {includeFooter && <FooterOne />}
            </main>
        </>
    );
}

export default Layout;
