// Enroll.js
import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbTwo from '../../common/breadcrumb/BreadcrumbTwo';
import CourseData from '../../data/course/CourseData';
import ScheduleData from '../../data/course/ScheduleData.json';
import StripeHandler from '../../services/StripeHandler';
import Dropdown from 'react-bootstrap/Dropdown';

import { auth, getUserEmail, firestore } from "../../firebase";
import { collection, getDocs, query, where } from 'firebase/firestore';

import './_enroll.scss';

const Enroll = () => {
  const stripeHandler = new StripeHandler();

  const [courses, setCourses] = useState([]);
  const [checkoutError, setCheckoutError] = useState("");

  const [selectedStudentsMap, setSelectedStudentsMap] = useState(
    JSON.parse(localStorage.getItem('selectedStudentsMap')) || {}
  );
  const [enrolledClasses, setEnrolledClasses] = useState({});

  let isMobile = window.innerWidth <= 768;
  useEffect(() => {
    window.addEventListener('resize', () => {
      isMobile = window.innerWidth <= 768;
      console.log('isMobile', isMobile);
    });
  }, []);

  useEffect(() => {
    const thisSelectedStudentsMap =
    JSON.parse(localStorage.getItem('selectedStudentsMap')) || {};

    let selectedStudentsMapUpdate = {...thisSelectedStudentsMap};
    Object.entries(enrolledClasses).map(([classId, classData]) => {
      Object.keys(classData).forEach((classDate) => {
        if (selectedStudentsMapUpdate[classId] && selectedStudentsMapUpdate[classId][classDate]){
          delete selectedStudentsMapUpdate[classId][classDate];
        }
      });
    });
    setSelectedStudentsMap(selectedStudentsMapUpdate);
  }, []);

  useEffect(() => {
    let selectedStudentsMapUpdate = {...selectedStudentsMap};
    Object.entries(enrolledClasses).map(([classId, classData]) => {
      Object.keys(classData).forEach((classDate) => {
        if (selectedStudentsMapUpdate[classId] && selectedStudentsMapUpdate[classId][classDate]){
          delete selectedStudentsMapUpdate[classId][classDate];
        }
      });
    });

    localStorage.setItem('selectedStudentsMap', JSON.stringify(selectedStudentsMapUpdate));
  }, [selectedStudentsMap]);

  useEffect(() => {
    const getEnrolledClasses = async () => {
      try {
        const userEmail = await getUserEmail(); // Wait for the promise to resolve
        if (userEmail) {

          const q = query(collection(firestore, 'enrollment'), where('email', '==', userEmail), where('status', '==', 'enrolled'));
          const enrolledClassesSnapshot = await getDocs(q);

          const enrolledClasses = enrolledClassesSnapshot.docs.map((doc) => doc.data());
          console.log("enrolledClasses", enrolledClasses);
      
          const updatedStudentsMap = {};
          enrolledClasses.forEach(({ class_id, date, students }) => {
            if (!updatedStudentsMap[class_id]) {
              updatedStudentsMap[class_id] = {};
            }
    
            updatedStudentsMap[class_id][date] = { num: students };
          });
          
          console.log("enrolled", updatedStudentsMap)
          // setSelectedStudentsMap(updatedStudentsMap);
          setEnrolledClasses(updatedStudentsMap);

          let selectedStudentsMapUpdate = {...selectedStudentsMap};
          Object.entries(enrolledClasses).map(([classId, classData]) => {
            Object.keys(classData).forEach((classDate) => {
              if (selectedStudentsMapUpdate[classId] && selectedStudentsMapUpdate[classId][classDate]){
                delete selectedStudentsMapUpdate[classId][classDate];
              }
            });
          });
          setSelectedStudentsMap(selectedStudentsMapUpdate);
        }
      } catch (error) {
        console.error("Error fetching enrolled classes:", error);
      }
    };

    getEnrolledClasses();

    let selectedStudentsMapUpdate = {...selectedStudentsMap};
    Object.entries(enrolledClasses).map(([classId, classData]) => {
      Object.keys(classData).forEach((classDate) => {
        if (selectedStudentsMapUpdate[classId] && selectedStudentsMapUpdate[classId][classDate]){
          delete selectedStudentsMapUpdate[classId][classDate];
        }
      });
    });
    setSelectedStudentsMap(selectedStudentsMapUpdate);
    


  }, [firestore]);

  useEffect(() => {
    const getProducts = async () => {
      try {
        const products = await stripeHandler.getProducts();
        let newProducts = [];
        products.data.forEach(product => {
          const thisClass = CourseData.find((item) => item.class_id === product.metadata.class_id);
          const schedule = ScheduleData.find((item) => item.class_id === product.metadata.class_id);
            
          const thisProduct = {
            ...thisClass,
            payment: product,
            schedule: schedule ? schedule.schedule : [],
          }
          if(thisProduct['class_id']){
            newProducts.push(thisProduct);
          }
        });
        newProducts = newProducts.sort((a, b) => (a.class_id > b.class_id) ? 1 : -1);
        setCourses(newProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    getProducts();
  }, []);

  const formatDateTime = (dateTime) => {
    const formattedDate = new Date(dateTime).toLocaleString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });

    return formattedDate;
  };


  const getClassSchedule = (classId) => {
    const schedule = ScheduleData.find((item) => item.class_id === classId);
    return schedule ? schedule : [];
  };

  const handleStudentSelect = (classId, date, num) => {
    const updatedStudentsMap = {
        ...selectedStudentsMap,
        [classId]: {
            ...selectedStudentsMap[classId],
            [date]: {
                num: num,
            },
        },
    };
    Object.entries(enrolledClasses).map(([classId, classData]) => {
      Object.keys(classData).forEach((classDate) => {
        if (updatedStudentsMap[classId] && updatedStudentsMap[classId][classDate]){
          delete updatedStudentsMap[classId][classDate];
        }
      });
    });

    console.log("updatedStudentsMap", updatedStudentsMap);
    setSelectedStudentsMap(updatedStudentsMap);
  };

  const getCoursesMobile = () => {
    console.log("courses", courses);
    let totalForEnrolledClasses = 0;

    return (
      courses.map((classData) => (
        <React.Fragment key={`class-${classData.class_id}`}>
          <tr key={`wrap-${classData.class_id}`} style={{ display: 'table-row' }}>
            <td className='class-title'>{classData.title}</td>
          </tr>
          <tr key={`classes-$ {classData.class_id}`} style={{ display: 'table-row' }}>
            <td className={`class-schedule ${isMobile ? 'mobile' : ''}`}>
                  {classData.schedule.map(({ startTime, endTime }, index) => (
                    <Table key={`${classData.class_id}-${index}-table`}>
                    <tbody>
                    <tr
                      className={`class-group ${selectedStudentsMap[classData.class_id]?.[startTime]?.num > 0 ? 'select' : ''} ${enrolledClasses[classData.class_id]?.[startTime]?.num > 0 ? 'enrolled' : ''}`}
                      key={`${classData.class_id}-${index}-date`}
                    >
                      <td className='date-header'>Date</td>
                      <td className='class-dates'>
                        <div key={index}>
                          {formatDateTime(startTime)} - {formatDateTime(endTime)}
                        </div>
                      </td>
                    </tr>
                    <tr
                      className={`class-group ${selectedStudentsMap[classData.class_id]?.[startTime]?.num > 0 ? 'select' : ''} ${enrolledClasses[classData.class_id]?.[startTime]?.num > 0 ? 'enrolled' : ''}`}
                      key={`${classData.class_id}-${index}-price`}
                    >
                      <td className='price-header'>Price</td>
                      <td className='class-price'>${classData.price.toFixed(2)}</td>
                    </tr>
                    <tr
                      className={`class-group ${selectedStudentsMap[classData.class_id]?.[startTime]?.num > 0 ? 'select' : ''} ${enrolledClasses[classData.class_id]?.[startTime]?.num > 0 ? 'enrolled' : ''}`}
                      key={`${classData.class_id}-${index}-enrolled`}
                    >
                      <td className='quantity-header'>Quantity</td>
                      <td className='class-enrolled'>
                        <div>
                        {enrolledClasses[classData.class_id] &&
                          enrolledClasses[classData.class_id][startTime] &&
                          enrolledClasses[classData.class_id][startTime].num > 0 ? (
                            <span className='students-enrolled'>{`${enrolledClasses[classData.class_id][startTime].num} Student${enrolledClasses[classData.class_id][startTime].num !== 1 ? 's' : ''}`}</span>
                          ) : (
                            <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                {selectedStudentsMap[classData.class_id] && selectedStudentsMap[classData.class_id][startTime]
                                    ? `${selectedStudentsMap[classData.class_id][startTime].num} Student${selectedStudentsMap[classData.class_id][startTime].num !== 1 ? 's' : ''}`
                                    : 'Enroll'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {[0, 1, 2, 3, 4].map((num) => (
                                <Dropdown.Item key={num} onClick={() => handleStudentSelect(classData.class_id, startTime, num)}>
                                  {num} Student{num !== 1 ? 's' : ''}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                        </div>
                      </td>
                    </tr>
                    <tr
                      className={`class-group ${selectedStudentsMap[classData.class_id]?.[startTime]?.num > 0 ? 'select' : ''} ${enrolledClasses[classData.class_id]?.[startTime]?.num > 0 ? 'enrolled' : ''}`}
                      key={`${classData.class_id}-${index}-total`}
                    >
                      <td className='total-header'>Total</td>
                      <td className='class-total-price'>
                        {enrolledClasses[classData.class_id]?.[startTime]?.num > 0 ? (
                          // If class is enrolled, show the total for enrolled classes
                          `$${(classData.price * enrolledClasses[classData.class_id]?.[startTime]?.num).toFixed(2)} (Enrolled)`
                        ) : (
                          // If class is not enrolled, show the total for selected students
                          `$${(classData.price * (selectedStudentsMap[classData.class_id]?.[startTime]?.num || 0)).toFixed(2)}`
                        )}
                      </td>
                    </tr>
                    </tbody>
                    </Table>
                  ))}            
            </td>
          </tr>
        </React.Fragment>
      ))
    )
  };

  const getCoursesDesktop = () => {
    console.log("courses", courses);
    let totalForEnrolledClasses = 0;

    return (
      courses.map((classData) => (
        <tr key={`wrap-${classData.class_id}`} style={{ display: 'table-row' }}>
          <td className='class-title'>{classData.title}</td>
          <td className={`class-schedule ${isMobile ? 'mobile' : ''}`}>
            <Table>
              <thead>
                <tr key={`wrap-header-${classData.class_id}`}>
                  <th>Date</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {classData.schedule.map(({ startTime, endTime }, index) => (
                  <tr
                    className={`class-group ${selectedStudentsMap[classData.class_id]?.[startTime]?.num > 0 ? 'select' : ''} ${enrolledClasses[classData.class_id]?.[startTime]?.num > 0 ? 'enrolled' : ''}`}
                    key={`${classData.class_id}-${index}`}
                  >
                    <td className='class-dates'>
                      <div key={index}>
                        {formatDateTime(startTime)} - {formatDateTime(endTime)}
                      </div>
                    </td>
                    <td className='class-price'>${classData.price.toFixed(2)}</td>
                    <td className='class-enrolled'>
                      <div>
                      {enrolledClasses[classData.class_id] &&
                        enrolledClasses[classData.class_id][startTime] &&
                        enrolledClasses[classData.class_id][startTime].num > 0 ? (
                          <span className='students-enrolled'>{`${enrolledClasses[classData.class_id][startTime].num} Student${enrolledClasses[classData.class_id][startTime].num !== 1 ? 's' : ''}`}</span>
                        ) : (
                          <Dropdown>
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                              {selectedStudentsMap[classData.class_id] && selectedStudentsMap[classData.class_id][startTime]
                                  ? `${selectedStudentsMap[classData.class_id][startTime].num} Student${selectedStudentsMap[classData.class_id][startTime].num !== 1 ? 's' : ''}`
                                  : 'Enroll'}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {[0, 1, 2, 3, 4].map((num) => (
                              <Dropdown.Item key={num} onClick={() => handleStudentSelect(classData.class_id, startTime, num)}>
                                {num} Student{num !== 1 ? 's' : ''}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                      </div>
                    </td>
                    <td className='class-total-price'>
                      {enrolledClasses[classData.class_id]?.[startTime]?.num > 0 ? (
                        // If class is enrolled, show the total for enrolled classes
                        `$${(classData.price * enrolledClasses[classData.class_id]?.[startTime]?.num).toFixed(2)} (Enrolled)`
                      ) : (
                        // If class is not enrolled, show the total for selected students
                        `$${(classData.price * (selectedStudentsMap[classData.class_id]?.[startTime]?.num || 0)).toFixed(2)}`
                      )}
                    </td>
                  </tr>
                ))}            
              </tbody>
            </Table>
          </td>
        </tr>
      ))
    )
  };

  const calculateTotal = () => {
    let total = 0;
    if (!selectedStudentsMap) return total;

    Object.entries(selectedStudentsMap).forEach(([classId, classData]) => {
      Object.entries(classData).forEach(([singleClassStart, singleClassDict]) => {
        if (courses.length > 0 && courses.find((item) => item.class_id === classId)) {
          total += singleClassDict.num * courses.find((item) => item.class_id === classId).price;
        }
      });
    });
    return total;
  };

  const handleCheckout = async () => {
    setCheckoutError("Checking out...");
    console.log("selectedStudentsMap", selectedStudentsMap);

    const classRows = Object.entries(selectedStudentsMap).map(([classId, classData]) => {
      const foundCourse = courses.find((item) => item.class_id === classId);
    
      if (foundCourse) {
        const num = Object.entries(classData).reduce((acc, [week, weekData]) => acc + weekData.num, 0);
        if(num != 0){
          return {
            class_id: classId,
            price_id: foundCourse.payment ? foundCourse.payment.price_id : null,
            num: num,
          };
        } else {
          return [];
        }
      } else {
        return [];
      }
    });

    const filteredClassRows = classRows.filter(row => row.num > 0);

    try {
      const stripePayIds = filteredClassRows.map((row) => ({
        quantity: row.num,
        price_id: row.price_id,
        class_id: row.class_id,
      }));

      console.log("stripePayIds", stripePayIds);

      const checkoutJson = await stripeHandler.checkout(stripePayIds, selectedStudentsMap);

      if (checkoutJson && checkoutJson.status === "error") {
        if(checkoutJson.message){
          setCheckoutError(`<b>Checkout Error</b><p>${checkoutJson.message}</p>`);
        } else {
          console.log("2", checkoutJson)
          setCheckoutError("<b>Checkout Error</b><p>There was an error checking out. Please try again.</p>");
        }
      }

      if(checkoutJson && checkoutJson.status === "success"){
        setCheckoutError("");
        window.location.href = checkoutJson.session_url;
      }

    } catch (error) {
      console.log("1", error)
      setCheckoutError("<b>Checkout Error</b><p>There was an error checking out. Please try again.</p>");
    }
  };

  return (
    <>
      <SEO title="Enrollment" />
      <Layout>
        <BreadcrumbTwo title="Enrollment" />
        <div className="edu-enroll bg-color-white">
          <div className="container mb--30 mt--30">
            <Table striped bordered hover>
              <thead>
                <tr key="classes">
                  <th className='classes-header'>Classes</th>
                </tr>
              </thead>
              <tbody>
                {isMobile ? getCoursesMobile() : getCoursesDesktop()}
              </tbody>
            </Table>
          </div>
          <div className="container mb--30 mt--30">
            <Table striped bordered hover>
              <thead>
                <tr key="enrollment">
                  <th>Enrollment Summary</th>
                </tr>
              </thead>
              <tbody>
                <tr key="total">
                  <td className='class-title'>Total</td>
                  <td className='class-schedule'>${calculateTotal().toFixed(2)}</td>
                </tr>
                <tr key="checkout-error" className='checkout-error'>
                  <td></td>
                  <td dangerouslySetInnerHTML={{__html: checkoutError}}></td>
                </tr>
                <tr key="checkout">
                  <td></td>
                  <td>
                    <button className='edu-btn' onClick={() => handleCheckout()} >
                      Checkout
                    </button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Enroll;
