// store.js
import { configureStore, createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
  reducers: {
    setUser: (state, action) => {
      const newState = action.payload;
      localStorage.setItem('user', JSON.stringify(newState));
      return newState;
    },
    clearUser: (state) => {
      localStorage.removeItem('selectedStudentsMap');
      localStorage.removeItem('user');
      return null;
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
  },
});

export default store;
