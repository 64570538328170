import React, { useState } from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import Auth from '../../Auth'; // Import the Auth class

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [resetSuccess, setResetSuccess] = useState(false);
  const [resetErrorMessage, setResetErrorMessage] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setEmail(value);
    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (isSubmitDisabled) {
      return;
    }

    let errors = {};
    let isValid = true;

    if (!email.trim()) {
      errors.email = 'Email is required';
      isValid = false;
    }

    setFormErrors(errors);

    if (isValid) {
      try {
        const auth = new Auth();

        setIsSubmitDisabled(true); // Disable the submit button during the process

        // Send a password reset email
        await auth.resetPassword(email);

        // Notify the user about the password reset email
        setResetSuccess(true);
        setResetErrorMessage('');
      } catch (error) {
        console.error('Error sending password reset email:', error.message);

        // Set the error message to display in the UI
        setResetSuccess(false);
        setResetErrorMessage(error.message);
      } finally {
        setIsSubmitDisabled(false); // Re-enable the submit button after the process
      }
    }
  };

  return (
    <>
      <SEO title="Reset Password" />
      <Layout>
        <div className="login-register-page-wrapper bg-color-white nav-top-padding">
          <div className="container checkout-page-style">
            <div className="row g-5">
              <div className="col-lg-6">
                {!resetSuccess ? (
                  <form className="reset-password-form" onSubmit={handleResetPassword}>
                    <h4>Reset Password</h4>
                    <div className="input-box mb--30">
                      <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={email}
                        onChange={handleInputChange}
                      />
                      <span className="error-message ml--25">{formErrors.email}</span>
                    </div>
                    <span className="reset-error-message error-message">{resetErrorMessage}</span>
                    <button
                      className="rn-btn edu-btn w-100 mb--30"
                      type="submit"
                      disabled={isSubmitDisabled}
                    >
                      <span>Reset Password</span>
                    </button>
                  </form>
                ) : (
                  <div className="reset-success-message">
                    <p>
                      An email has been sent to {email} with instructions on how to reset your password.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ResetPassword;
