import React from 'react';
import { Link } from 'react-router-dom';

const items = [
    {
        title: 'Dad with a Toolbox of Tech Tricks',
        info: 'Programming, 3D printing, game design and everything else that goes beep-boop! Every class is a blast and every project is a new mission. Wes loves sharing "aha" moments with his three kids and welcomes all tech explorers!',
        icon: 'icon-Hand---Book',
        iconUrl: '/images/wesworld/wesworld.svg'

    },
    {
        title: 'Tech Classes Tailored for Young Curious Minds',
        info: 'Wes crafts custom sessions that speak to what makes your childs eyes sparkle. Whether its engineering marvels to solve quirky problems or creating delightful gadgets that everyone adores, here we let curiosity and creativity take the lead.',
        icon: 'icon-Campus',
        iconUrl: '/images/wesworld/gear.svg'
    }
];

const About = () => {
    return (
        <div
            className="edu-about-area about-style-1 edu-section-gap"
        >
            <div className="container">
                <div className="row g-5">
                    <div className="col-lg-6">
                        <div className="about-image-gallery">
                            <img className="image-1" src="/images/wesworld/about/wes-0.jpg" alt="Wes Lorenzini" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="inner">
                            <div className="section-title">
                                <span className="pre-title">WesWorld</span>
                                <h1 className="title">Engineer, Inventor<br/> and Teacher </h1>
                            </div>
                            <p className="description"></p>
                            { items && items.length > 0 &&
                                <div className="about-feature-list">
                                    { items.map( ( data , i ) => (
                                        <div className="our-feature" key={i}>
                                            <div className="our-feature">
                                                <div className="icon">
                                                    <img src={ data.iconUrl } alt={ data.title } />
                                                </div>
                                                <div className="feature-content">
                                                    <h6 className="feature-title">{ data.title }</h6>
                                                    <p className="feature-description" dangerouslySetInnerHTML={{__html: data.info}}></p>
                                                </div>
                                            </div>
                                        </div>
                                    ) ) }
                                </div>
                            }
                            {/* <div className="read-more-btn">
                                <Link className="edu-btn" to="/about-us-1">Know About Us<i className="icon-arrow-right-line-right"></i></Link>
                            </div> */}
                            <div className="shape shape-6 about-parallax-2 d-xl-block d-none">
                                <img src="/images/shapes/shape-07.png" alt="Shape Thumb" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;