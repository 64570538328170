import React from 'react';
import { useLocation } from 'react-router-dom'; // Assuming you are using React Router
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import CourseTypeOne from '../../components/course/CourseTypeOne';
import CourseData from '../../data/course/CourseData.json';
import HeaderTwo from '../../common/header/HeaderTwo';
import { capitalizeFirstLetter } from '../../utils';

const CourseOne = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get('type') || "";
  
    let CourseItems = CourseData.filter(item => item.tags.includes(type));
    if(CourseItems.length === 0){
        CourseItems = CourseData;
    }

    return (
        <>
            <SEO title={capitalizeFirstLetter(type)+" Classes"} />
            <HeaderTwo styles="header-transparent header-style-2" />
            <Layout>
                <BreadcrumbOne 
                    title={"Classes"+(type ? " - "+capitalizeFirstLetter(type): "")}
                />
                <div className="edu-course-area bg-color-white">
                    <div className="container">
                        <div className="row g-5 mt-10">
                            { 
                                CourseItems.map((item) => (
                                    <div className="col-12 col-sm-6 col-lg-4" key={item.id}>
                                        <CourseTypeOne data={item} />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
}

export default CourseOne;
