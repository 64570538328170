// Nav.js
import React from 'react';
import { Link } from 'react-router-dom';

const Nav = ({ user }) => {
  return (
    <ul className="mainmenu">
      <li>
        <Link to="/">Home</Link>
      </li>

      <li className="has-dropdown">
        <Link to="/classes/">Classes</Link>
        <ul className="submenu">
          <li><Link to="/classes/">All</Link></li>
          <li><Link to="/classes?type=reading">Reading</Link></li>
          <li><Link to="/classes?type=writing">Writing</Link></li>
          <li><Link to="/classes?type=technology">Technology</Link></li>
          <li><Link to="/classes?type=engineering">Engineering</Link></li>
          <li><Link to="/classes?type=art">Art</Link></li>
        </ul>
      </li>

      <li className="has-dropdown services">
        <Link to="#">Services</Link>
        <ul className="submenu">
          <li><Link to="/services/assessment">👩‍🎓 Personalized Assessments</Link></li>
          <li><Link to="/services/tutoring">📚 Tutoring</Link></li>
        </ul>
      </li>

      <li className="has-dropdown">
        <Link to="/about/littlehouseofhugs">About</Link>
        <ul className="submenu">
          <li><Link to="/about/littlehouseofhugs">Little House of Hugs</Link></li>
          <li><Link to="/about/wesworld">WesWorld</Link></li>
          <li><Link to="/about/teachers">Our Teachers</Link></li>
        </ul>
      </li>

      <li>
        <Link to="/testimonials">Testimonials</Link>
      </li>
    </ul>
  );
};

export default Nav;
