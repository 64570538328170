import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import LoginForm from '../../components/form/LoginForm';
import { Link, useLocation } from 'react-router-dom';

const Login = () => {
    const location = useLocation();
    const nextPath = new URLSearchParams(location.search).get('next') || '';

    return (
        <>
            <SEO title="Login" />
            <Layout>
                <div className="login-register-page-wrapper bg-color-white nav-top-padding">
                    <div className="container checkout-page-style">
                        <div className="row g-5">
                            <div className="col-lg-6">
                                <LoginForm />
                            </div>
                        </div>
                        <div className="row pt-4 d-flex align-self-center">
                            <h5>Need an account? <Link to={nextPath ? `/register?next=${nextPath}` : "/register"}>Register</Link></h5>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Login;