import React from 'react';
import { Link } from 'react-router-dom';
import Nav from './Nav';

const MobileNav = (user) => {
    return (
        <Nav user={user} />
    )
}

export default MobileNav;