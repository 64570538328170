import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import AboutFive from '../../components/about/AboutFive';
import LogoOne from '../../components/logos/LogoOne';
import HomeFiveProgress from '../../components/home-five/HomeFiveProgress';
import AboutUsThreeMission from '../../components/about-us-three/AboutUsThreeMission';
import AboutUsThreeInstructor from '../../components/about-us-three/AboutUsThreeInstructor';
import CounterUpOne from '../../components/counterup/CounterUpOne';
import TestimonialSectionTwo from '../../components/testimonial-section/TestimonialSectionTwo';
import VideoSectionOne from '../../components/video-section/VideoSectionOne';
import ConsultingMission from '../../components/services/ConsultingMission';
import ConsultingVideo from '../../components/services/ConsultingVideo';
import TestimonialSectionFour from '../../components/testimonial-section/TestimonialSectionFour';
import TutoringService from '../../components/services/TutoringService';

const TutoringServicePage = () => {
    return (
        <>
            <SEO title="Tutoring" />
            <Layout>
                <TutoringService />
            </Layout>
        </>
    )
}

export default TutoringServicePage;