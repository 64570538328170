import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import Calendar from '../../components/calendar/Calendar';
import { slugify } from '../../utils';
import CourseData from '../../data/course/CourseData.json';

const MyClasses = () => {

  // Generate events from course schedule
  const generateEventsFromCourses = () => {
    const allEvents = [];

    CourseData.forEach((course) => {
      course.schedule.forEach((schedule) => {
        const event = {
          id: course.class_id,
          title: course.title,
          daysOfWeek: schedule.daysOfWeek,
          startTime: schedule.startTime,
          endTime: schedule.endTime,
        };

        allEvents.push(event);
      });
    });

    return allEvents;
  };

  const events = generateEventsFromCourses();

  return (
    <>
      <SEO title="Classes" />
      <Layout>
        <BreadcrumbOne title="Classes" />
        <div className="edu-instructor-profile-area bg-color-white">
          <div className="container">
            <div className="row g-5">
              <Calendar events={events} />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default MyClasses;
