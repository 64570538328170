import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

const SEO = ({ title, user }) => {
  useEffect(() => {
    if (user && user.uid) {
      LogRocket.identify(user.uid, {
        name: `${user.firstName} ${user.lastName}`,
        email: user.email
      });
    }
  }, [user]);

  return (
    <>
      <meta charSet="utf-8" />
      <title>{title} || Little House of Hugs</title>
      <meta name="robots" content="noindex, follow" />
      <meta name="description" content="" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

      <meta property="og:title" content={`${title} || Little House of Hugs`} />
    </>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  user: PropTypes.object // Make sure to define the user prop type
};

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(SEO);
