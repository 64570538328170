import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import LoginForm from '../../components/form/LoginForm';
import { Link } from 'react-router-dom';

const EmailLinkSent = () => {
    return (
        <>
            <SEO title="Email Link Sent" />
            <Layout>
                <div className="email-sent-page-wrapper bg-color-white nav-top-padding">
                    <div className="container checkout-page-style">
                        <h4>Please check your email and click the link to continue.</h4>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default EmailLinkSent;