import React from 'react';
import TrackVisibility from 'react-on-screen';
import CountUp from 'react-countup';
import Slider from 'react-slick';
import { useParams, Link } from 'react-router-dom';
import { slugify } from '../../utils';
import { InstructorCourseSliderParams } from '../../utils/script';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import Skill from '../../components/progressbar/ProgressbarItem';
import CourseTypeTwo from '../../components/course/CourseTypeTwo';
import InstructorData from '../../data/instructor/InstructorData.json';
import CourseData from '../../data/course/CourseData.json';

import {parseEmojis} from '../../utils/parseEmojis';


import "./_MyAccount.scss";

const MyAccount = () => {
    return (
        <>
            <SEO title="My Account" />
            <Layout className="my-account-wrapper">
                <BreadcrumbOne 
                    title="My Account"
                />
                <div className="my-account-area bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                        <ul className="submenu">
                            <li><Link to="/enroll">Enrollment</Link></li>
                            <li><Link to="/logout">Logout</Link></li>
                        </ul>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default MyAccount;