//LoginForm.js
import React, { useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Auth from '../../Auth'; // Import the Auth class

const LoginForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [formErrors, setFormErrors] = useState({
    email: '',
    password: '',
  });

  const [isFormValid, setIsFormValid] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const navigate = useNavigate();

  const failedAttemptCountRef = useRef(0);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (isSubmitDisabled) {
      return;
    }
  
    let errors = {};
    let isValid = true;
  
    if (!formData.email.trim()) {
      errors.email = 'Email is required';
      isValid = false;
    }
  
    if (!formData.password.trim()) {
      errors.password = 'Password is required';
      isValid = false;
    }
  
    setFormErrors(errors);
    setIsFormValid(isValid);
  
    if (isValid) {
      try {
        const auth = new Auth();
  
        // Attempt to login the user
        await auth.loginUser(formData.email, formData.password);
  
        // Reset failed attempt count on successful login
        failedAttemptCountRef.current = 0;
  
        console.log('User logged in successfully!');
        // Redirect to the desired page after successful login
        
         // Check for the 'next' parameter in the URL query string
         const nextPath = new URLSearchParams(location.search).get('next');
         if (nextPath) {
           // If 'next' parameter is present, navigate to the specified path
           navigate(nextPath);
         } else {
           // If no 'next' parameter, navigate to the default path
           navigate('/');
         }

      } catch (error) {
        console.error('Error logging in user:', error.message);
  
        // Set the error message to display in the UI
        setLoginErrorMessage(error.message);
  
        // Clear the password field on error
        setFormData((prevData) => ({
          ...prevData,
          password: '',
        }));
  
        // Handle the case where the account is temporarily disabled due to many failed attempts
        if (error.code === 'auth/too-many-requests') {
          setIsSubmitDisabled(true);
          failedAttemptCountRef.current += 1;
  
          // You may want to display a user-friendly message to inform the user about too many failed attempts
          console.error('Account temporarily disabled due to too many failed login attempts.');
        }
      }
    }
  };
  

  return (
    <div className="login-form-box">
      <h3 className="mb-30">Login</h3>
      <form className="login-form" onSubmit={handleSubmit}>
        <div className="input-box mb--30">
          <input
            type="text"
            placeholder="Email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
          />
          <span className="error-message ml--25">{formErrors.email}</span>
        </div>
        <div className="input-box mb--30">
          <input
            type="password"
            placeholder="Password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
          />
          <span className="error-message ml--25">{formErrors.password}</span>
        </div>
        <div className="comment-form-consent input-box mb--30">
          <input id="checkbox-1" type="checkbox" />
          <label htmlFor="checkbox-1">Remember Me</label>
        </div>
        <span className="login-error-message error-message">{loginErrorMessage}</span>
        <button
          className="rn-btn edu-btn w-100 mb--30"
          type="submit"
          disabled={isSubmitDisabled}
        >
          <span>Login</span>
        </button>
        <div className="input-box">
          <Link to="/auth/reset" className="reset-password">
            Reset password
          </Link>
        </div>
      </form>
    </div>
  );

};

export default LoginForm;
