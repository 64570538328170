import React from 'react';
import { Link } from 'react-router-dom';
import './_About.scss';

const items = [
    {
        title: 'Your Educational Ally',
        info: 'Your bestie in this wild world of education. Need help homeschooling your kiddo? No problem. Struggling to connect with your child\'s teacher? I\'ve got your back.',
        icon: 'icon-Hand---Book',
        iconUrl: '/images/littlehouseofhugs/ally.svg'
    },
    {
        class: 'hugs-and-high-fives',
        title: 'Hugs and High Fives',
        info: 'Every child is wrapped in a warm blanket of personalized learning, woven by research-backed methods from the Science of Reading, Wilson Fundations and Orton-Gillingham to help readers and writers flourish at their own unique pace.        ',
        icon: 'icon-Campus',
        iconUrl: '/images/littlehouseofhugs/house.svg'
    }
];

const About = ({ imagePosition }) => {
    const isImageLeft = imagePosition === 'left';

    return (
        <>
            <div className={`edu-about-area about-style-1 lhh-about ${isImageLeft ? 'image-left' : 'image-right'}`}>
                <div className="container">
                    <div className="row g-5">
                        <div className={`col-lg-6 ${isImageLeft ? 'order-lg-1' : ''}`}>
                            <div className="about-image-gallery ml--50">
                                <img className="image-1" src="/images/littlehouseofhugs/services/services-1.jpg" alt="" />
                                <div className="shape-image shape-image-1">
                                    <img src="/images/shapes/shape-04-01.png" alt="Shape" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 g--40">
                            <div className="inner">
                                <div className="section-title">
                                    <span className="pre-title">Little House of Hugs</span>
                                    <h1 className="title">Classes, Consulting,  <div> </div>Curriculum and Care</h1>
                                </div>
                                <p className="description"> <b>Remember that old saying, "It takes a village to raise a child"?<br>
                                </br> Isn't it time we brought back that village vibe for our kids' education?</b></p>
                                { items && items.length > 0 &&
                                    <div className="about-feature-list">
                                        { items.map( ( data , i ) => (
                                            <div className={`our-feature ${data.class ? data.class : ''}`} key={i}>
                                                <div className="our-feature">
                                                    <div className="icon">
                                                        <img src={ data.iconUrl } alt={ data.title } />
                                                    </div>
                                                    <div className="feature-content">
                                                        <h6 className="feature-title">{ data.title }</h6>
                                                        <p className="feature-description">{ data.info }</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ) ) }
                                    </div>
                                }
                                <div className="shape shape-6 about-parallax-2 d-xl-block d-none">
                                    <img src="/images/shapes/shape-07.png" alt="Shape Thumb" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About;




