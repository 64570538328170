import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import Auth from '../../Auth'; // Import the Auth class

const RegisterForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    agreeTerms: false
  });

  const [formErrors, setFormErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    agreeTerms: '',
  });

  const [isFormValid, setIsFormValid] = useState(false);
  const [registerErrorMessage, setRegisterErrorMessage] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // New state for button disable
  const [auth] = useState(() => new Auth()); // Initialize the auth instance once
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const handleRecaptchaChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
    }));

    setFormErrors((prevErrors) => ({
      ...prevErrors,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let errors = {};
    let isValid = true;
    setRegisterErrorMessage('');

    if (!formData.firstName.trim()) {
      errors.firstName = 'First Name is required';
      isValid = false;
    }

    if (!formData.lastName.trim()) {
      errors.lastName = 'Last Name is required';
      isValid = false;
    }

    if (!formData.email.trim()) {
      errors.email = 'Email is required';
      isValid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email.trim())) {
      errors.email = 'Invalid email address';
      isValid = false;
    }

    if (!formData.password.trim()) {
      errors.password = 'Password is required';
      isValid = false;
    } else if (formData.password.trim().length < 8) {
      errors.password = 'Password must be at least 8 characters';
      isValid = false;
    }

      // Check whether the user has agreed to the terms and conditions
    if (!formData.agreeTerms) {
        errors.agreeTerms = 'You must agree to the Terms & Conditions';
        isValid = false;
    }

    setFormErrors(errors);
    setIsFormValid(isValid);

    if (isValid) {
      try {
        if (formData.email) {
          await auth.registerUser(
            formData.firstName,
            formData.lastName,
            formData.email,
            formData.password
          );

          // Check for the 'next' parameter in the URL query string
          const nextPath = new URLSearchParams(location.search).get('next');
          if (nextPath) {
            // If 'next' parameter is present, navigate to the specified path
            navigate(nextPath);
          } else {
            // If no 'next' parameter, navigate to the default path
            navigate('/');
          }
        }
      } catch (error) {
        console.error('Error registering user:', error.message);
        setRegisterErrorMessage(error.message);
        setIsButtonDisabled(true); // Disable button on error
      }
    }
  };

  // Function to enable the Register button if any form field changes
  const handleFormChange = () => {
    setIsButtonDisabled(false);
  };

  return (
    <div className="login-form-box">
      <h3 className="mb-30">Register</h3>
      <form className="login-form" onSubmit={handleSubmit} onChange={handleFormChange}>
        <div className="input-box mb--30">
          <input
            type="text"
            placeholder="First Name"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
          />
          <span className="error-message ml--25">{formErrors.firstName}</span>
        </div>
        <div className="input-box mb--30">
          <input
            type="text"
            placeholder="Last Name"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
          />
          <span className="error-message ml--25">{formErrors.lastName}</span>
        </div>
        <div className="input-box mb--30">
          <input
            type="email"
            placeholder="Email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
          />
          <span className="error-message ml--25">{formErrors.email}</span>
        </div>
        <div className="input-box mb--30">
          <input
            type="password"
            placeholder="Password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
          />
          <span className="error-message ml--25">{formErrors.password}</span>
        </div>
        <div className="input-box mb--20">
          <input
            id="checkbox-2"
            type="checkbox"
            name="agreeTerms"
            checked={formData.agreeTerms}
            onChange={handleCheckboxChange}
          />
          <span className="error-message">{formErrors.agreeTerms}</span>
          <label htmlFor="checkbox-2">
            I read & agree the <Link to="/terms" target="_blank">Terms & Conditions</Link> and <Link to="/privacy-policy" target="_blank">Privacy Policy</Link>.
          </label>
        </div>
        <span className="register-error-message error-message">{registerErrorMessage}</span>
        <button
          className="rn-btn edu-btn w-100 mb--30"
          type="submit"
          disabled={isButtonDisabled}
        >
          <span>Register</span>
        </button>
      </form>
    </div>
  );
};

export default RegisterForm;
