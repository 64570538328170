import React from 'react'
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

const Terms = () => {
    return (
        <>
            <SEO title="Terms of Service" />
            <Layout>
                <BreadcrumbOne 
                    title="Terms of Service"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Terms of Service"
                />
                <div className="edu-privacy-policy-area edu-privacy-policy bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="content">   
                                <h3>Terms of Service</h3>
                                <p><strong>Effective Date:</strong> 01/02/2024</p>

                                <p>Welcome to Little House of Hugs! Thank you for choosing to use our website at littlehouseofhugs.com ("we," "us," or "our"). Please read these Terms of Service ("Terms") carefully before using our website and any services provided through it.</p>

                                <h4>1. Acceptance of Terms</h4>
                                <p>By using our website, you agree to be bound by these Terms, our Privacy Policy, and any additional terms and conditions that may apply to specific services. If you do not agree to these Terms, you may not use our website.</p>

                                <h4>2. Changes to Terms</h4>
                                <p>We reserve the right to modify or update these Terms at any time, with or without notice. Your continued use of the website after any changes indicates your acceptance of the updated Terms.</p>

                                <h4>3. Use of the Website</h4>
                                <p>You agree to use the website for lawful purposes and in a way that does not violate the rights of others. You may not use the website for any illegal or unauthorized purpose and must comply with all applicable laws and regulations.</p>

                                <h4>4. User Accounts</h4>
                                <p>To access certain features of our website, you may be required to create a user account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</p>

                                <h4>5. Content</h4>
                                <p>You are solely responsible for any content you post or share on our website. By posting content, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, publish, and distribute the content on our website and other promotional materials.</p>

                                <h4>6. Intellectual Property</h4>
                                <p>All content on our website, including text, graphics, logos, and images, is the property of Little House of Hugs or its licensors and is protected by copyright and other intellectual property laws.</p>

                                <h4>7. Termination</h4>
                                <p>We reserve the right to terminate or suspend your account and access to the website at our sole discretion, without prior notice, for any reason, including a breach of these Terms.</p>

                                <h4>8. Data Usage and Sharing</h4>
                                <p>By using Little House of Hugs, you agree to the collection, processing, and storage of your personal information as outlined in our Privacy Policy. We may use the collected data to improve our services, personalize your experience, and communicate with you about promotions, updates, and other relevant information. We may share your information with third-party service providers to facilitate our services, such as payment processing, analytics, and marketing. These third parties are obligated to protect your data and are prohibited from using it for any purpose other than providing services to Little House of Hugs.</p>

                                <h5>8.1 Consent:</h5>
                                <p>By using Little House of Hugs, you consent to the transfer, processing, and storage of your information as described in this section.</p>

                                <h5>8.2 Data Security:</h5>
                                <p>We implement reasonable security measures to protect your data from unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>

                                <h4>9. Disclaimer of Warranties</h4>
                                <p>Our website is provided "as is" without any warranties, express or implied. We do not guarantee the accuracy, completeness, or reliability of any content on the website.</p>

                                <h4>10. Limitation of Liability</h4>
                                <p>To the fullest extent permitted by law, Little House of Hugs shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the website.</p>

                                <p>By using our website, you agree to these Terms. If you have any questions or concerns, please contact us at <a href="mailto:contact@littlehouseofhugs.com">contact@littlehouseofhugs.com</a>.</p>

                                <p>Thank you for being part of Little House of Hugs!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default Terms;