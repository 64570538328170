import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { clearUser } from '../../store';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Use useNavigate instead of useHistory

  useEffect(() => {
    // Dispatch the action to clear user data from Redux store
    dispatch(clearUser());

    // Clear cookies
    document.cookie.split(';').forEach(function (c) {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });

    // Clear session storage
    sessionStorage.clear();

    // Redirect to / after 10 seconds
    const timeoutId = setTimeout(() => {
      navigate('/');
    }, 10000);

    // Clear the timeout when the component unmounts
    return () => clearTimeout(timeoutId);
  }, [dispatch, navigate]);

  return (
    <>
      <SEO title="Logout" />
      <Layout>
        <div className="logged-out-page-wrapper bg-color-white nav-top-padding">
          <div className="container checkout-page-style">
            <h4>See you soon!</h4>
            <p>You have been logged out.</p>
            <p>Redirecting to the home page in 10 seconds...</p>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Logout;
