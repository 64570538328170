import React, { useState, useEffect } from 'react';
import Masonry from 'react-masonry-css';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import MasonryItem from '../../components/gallery/MasonryItem';

const breakpointColumnsObj = {
  default: 3,
  991: 2,
  767: 1
};

const CourseGallery = ({ data }) => {
  const [getAllItems] = useState(data || []);
  const [activeFilter, setActiveFilter] = useState('');
  const [visibleItems, setVisibleItems] = useState([]);

  useEffect(() => {
    setActiveFilter(''); // You may set the initial filter if needed
    setVisibleItems(getAllItems);
  }, [data]);

  const handleChange = (e) => {
    e.preventDefault();
    setActiveFilter(e.target.textContent.toLowerCase());
    let tempData;
    if (e.target.textContent.toLowerCase() === '') {
      tempData = getAllItems;
    } else {
      tempData = getAllItems.filter((data) => data.category === e.target.textContent.toLowerCase());
    }
    setVisibleItems(tempData);
  };

  return (
    <>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="eduvibe-masonry-grid"
        columnClassName="eduvibe-masonry-grid_column"
      >
        {visibleItems.map((item) => (
          <MasonryItem key={item.id} data={item} imgSrc="masonry" />
        ))}
      </Masonry>
    </>
  );
};

export default CourseGallery;
