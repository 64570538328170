import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './components/scrolltotop/ScrollToTop';
import AppRoutes from './Routes';  // Import the Routes component
import { Provider } from 'react-redux';
import store from './store'; // Import your Redux store

// Import the analytics functions
import { initGA, logPageView } from './utils/analytics';

function App() {
  useEffect(() => {
    // Initialize Google Analytics and log the initial pageview
    initGA();
    logPageView();
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <ScrollToTop>
          <AppRoutes /> {/* Use the AppRoutes component */}
        </ScrollToTop>
      </Router>
    </Provider>
  );
}

export default App;
