// Auth.js
import { initializeApp } from 'firebase/app';
import { doc, getFirestore, collection, getDoc, setDoc } from 'firebase/firestore';
import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth';
import bcrypt from 'bcryptjs';
import store from './store'; // Adjust the path accordingly
import { setUser, clearUser } from './store'; // Adjust the path accordingly
import { auth, firestore } from "./firebase";

class Auth {
  constructor() {
    this.auth = auth;
    this.firestore = firestore;
  }

  getCurrentUser() {
    return this.auth.currentUser;
  }

  async waitForUserAuthentication() {
    return new Promise((resolve) => {
      const unsubscribe = onAuthStateChanged(this.auth, (user) => {
        if (user) {
          // User is authenticated
          unsubscribe(); // Stop listening for changes
          resolve(user);
        }
      });
    });
  }

  async registerUser(firstName, lastName, email, password) {
    try {
      console.log("this.auth: ", this.auth);
      // Register the user with Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(
        this.auth,
        email,
        password
      );
      const user = userCredential.user;

      // Wait for the user to be authenticated before proceeding
      await this.waitForUserAuthentication();

      // Get the user's IP address (this is a basic example, consider server-side handling for accurate IP)
      const ipAddress = await this.getUserIpAddress();

      // Log the data you are attempting to add
      console.log('Adding user data to Firestore:', {
        userId: user.uid,
        firstName,
        lastName,
        email,
        date_created: new Date().toISOString(),
        ipAddress
      });

      // Create a user document in Firestore with additional information
      await this.addUserToFirestore(
        user.uid,
        firstName,
        lastName,
        email,
        ipAddress
      );

      // Fetch the user document from Firestore
      const userDoc = await this.getUserFromFirestore(user.uid);

      // Combine auth user data and Firestore user data
      const userData = {
        uid: user.uid,
        email: user.email,
        firstName: userDoc.firstName,
        lastName: userDoc.lastName,
        // Add other fields as needed
      };

      // Dispatch the combined user information to the Redux store
      store.dispatch(setUser(userData));

      console.log('User registered successfully and sign-in link sent:', userData);
      return user;
    } catch (error) {
      // Log the error details
      console.error('Error registering user:', error.message);

      // Dispatch null to clear user data if registration fails
      store.dispatch(clearUser());

      throw error;
    }
  }

  async isAuthenticated() {
    return new Promise((resolve) => {
      const unsubscribe = onAuthStateChanged(this.auth, (user) => {
        unsubscribe(); // Stop listening for changes
        resolve(!!user); // Resolve with a boolean indicating whether the user is authenticated
      });
    });
  }

  // Fetch user document from Firestore
  async getUserFromFirestore(userId) {
    try {
      if (!this.firestore) {
        console.error('Firestore instance is not initialized.');
        throw new Error('Firestore instance is not initialized.');
      }
  
      const usersCollection = collection(this.firestore, 'users');
      const userDocRef = doc(usersCollection, userId);
      const userDocSnapshot = await getDoc(userDocRef);
  
      if (userDocSnapshot.exists()) {
        // Return the data from the user document
        return userDocSnapshot.data();
      } else {
        console.error('User document not found in Firestore.');
        throw new Error('User document not found.');
      }
    } catch (error) {
      console.error('Error fetching user document from Firestore:', error.message);
      throw error;
    }
  }

  async loginUser(email, password) {
    try {
      console.log("this.auth: ", this.auth);
      // Login the user with Firebase Authentication
      const userCredential = await signInWithEmailAndPassword(
        this.auth,
        email,
        password
      );
      const user = userCredential.user;

      // Fetch the user document from Firestore
      const userDoc = await this.getUserFromFirestore(user.uid);

      // Combine auth user data and Firestore user data
      const userData = {
        uid: user.uid,
        email: user.email,
        firstName: userDoc.firstName,
        lastName: userDoc.lastName,
        dateCreated: userDoc.dateCreated,
        // Add other fields as needed
      };

      // Dispatch the combined user information to the Redux store
      store.dispatch(setUser(userData));

      console.log('User logged in successfully:', userData);
      return user;
    } catch (error) {
      // Handle the case where the credential is invalid
      if (error.code === 'auth/invalid-credential') {
        console.error('Error logging in user:', 'Invalid login.');
        throw new Error('Invalid login. Please try again.');
      }

      console.error('Error logging in user:', error.message);
      throw error;
    }
  }

  async resetPassword(email) {
    try {
      // Send a password reset email
      await sendPasswordResetEmail(this.auth, email);

      console.log('Password reset email sent successfully.');
    } catch (error) {
      console.error('Error sending password reset email:', error.message);
      throw error;
    }
  }

  async addUserToFirestore(userId, firstName, lastName, email, ipAddress) {
    try {
      const usersCollection = collection(this.firestore, 'users');
      const userDocRef = doc(usersCollection, userId);
  
      // Check if the document already exists
      const userDocSnapshot = await getDoc(userDocRef);
      if (userDocSnapshot.exists()) {
        console.log('User document already exists in Firestore.');
        // You can handle this case based on your application requirements
        // For example, you might want to update the existing document or throw an error.
      } else {
        // Document doesn't exist, create it
        const docRef = await setDoc(userDocRef, {
          userId,
          firstName,
          lastName,
          email,
          dateCreated: new Date().toISOString(),
          ipAddress,
        });

        console.log('User added to Firestore successfully');
      }
    } catch (error) {
      // Log the error details
      console.error('Error adding user to Firestore:', error.message);
      throw error;
    }
  }

  async hashPassword(password) {
    const saltRounds = 10;
    return bcrypt.hash(password, saltRounds);
  }

  async getUserIpAddress() {
    try {
      const response = await fetch('https://api64.ipify.org?format=json');
      const data = await response.json();
      return data.ip.toString();
    } catch (error) {
      console.error('Error getting user IP address:', error.message);
      return "";
    }
  }
}

export default Auth;
