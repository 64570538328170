import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import TestimonialFour from '../../components/testimonial/TestimonialFour';
import TestimonialSectionFour from '../../components/testimonial-section/TestimonialSectionFour';
import TestimonialSectionOne from '../../components/testimonial-section/TestimonialSectionOne';
import TestimonialSectionTwo from '../../components/testimonial-section/TestimonialSectionTwo';

import "./_Testimonial.scss";

const Testimonial = () => {
    return (
        <>
            <SEO title="Testimonials" />
            <Layout>
                <div className="testimonial-page eduvibe-home-two-testimonial edu-testimonial-area testimonial-card-box-bg edu-section-gap bg-image">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="pre-title">Testimonials</span>
                                    <h3 className="title">Our Little House of Hugs Feedback</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row g-5">
                            <div className="col-lg-5 m-auto mt--60">
                                <img src="/images/testimonial-section/lhh-testimonial-1.jpg" alt="Testimonal" />
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Testimonial;