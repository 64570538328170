import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import CourseData from '../../data/course/CourseData.json';
import './_enroll.scss';
// import { useStripeHandler } from '../../services/StripeHandler';
import Auth from '../../Auth';
import { useStripeHandler } from '../../services/StripeHandler';

const EnrollThankYou = () => {
  localStorage.removeItem('selectedStudentsMap');

  return (
    <>
      <SEO title="Thank You" />
      <Layout>
        <BreadcrumbOne title="Thank You" />
        <div className="edu-enroll bg-color-white">
          <div className="container mt--20">
            <h5>It's an honor to work with your family.</h5>
            <p>
              We will be in touch with you shortly to confirm your enrollment.
            </p>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default EnrollThankYou;
