import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import LoginForm from '../../components/form/LoginForm';
import { Link } from 'react-router-dom';

const EmailLinkReturn = () => {
    return (
        <>
            <SEO title="Email Link Return" />
            <Layout>
                <div className="email-link-return-page-wrapper bg-color-white nav-top-padding">
                    <div className="container checkout-page-style">
                        <h4>Welcome back!</h4>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default EmailLinkReturn;