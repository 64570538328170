import React from 'react';
import SEO from '../../../common/SEO';
import Layout from '../../../common/Layout';
import BreadcrumbOne from '../../../common/breadcrumb/BreadcrumbOne';
import AboutUsTwoService from '../../../components/about-us-two/AboutUsTwoService';
import AwardOne from '../../../components/award/AwardOne';
import AboutUsTwoInstructor from '../../../components/about-us-two/AboutUsTwoInstructor';
import AboutOne from '../../../components/about/AboutOne';
import AboutUsTwoCountDown from '../../../components/about-us-two/AboutUsTwoCountDown';
import TestimonialSectionOne from '../../../components/testimonial-section/TestimonialSectionOne';

import About from './About';
import ConsultingMission from '../../../components/services/ConsultingMission';
import ConsultingVideo from '../../../components/services/ConsultingVideo';
import AboutLittleHouseOfHugs from '../../../components/about/AboutLittleHouseOfHugs';

const LittleHouseOfHugs = () => {
    return (
        <>
            <SEO title="Little House of Hugs" />
            <Layout>
                <AboutLittleHouseOfHugs />
                <div className="mt--50 mb--50">
                    <About imagePosition="left" />
                </div>
            </Layout>

        </>
    )
}

export default LittleHouseOfHugs;