//Register
import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import RegisterForm from '../../components/form/RegisterForm';
import { Link, useLocation } from 'react-router-dom';

const Register = () => {
  const location = useLocation();
  const nextPath = new URLSearchParams(location.search).get('next') || '';

  return (
    <>
      <SEO title="Register" />
      <Layout>
        <div className="login-register-page-wrapper bg-color-white nav-top-padding">
          <div className="container checkout-page-style">
            <div className="row g-5">
              <div className="col-lg-6">
                <RegisterForm />
              </div>
            </div>
            <div className="row pt-4 d-flex align-self-center">
              <h5>Already have an account? <Link to={nextPath ? `/login?next=${nextPath}` : "/login"}>Login</Link></h5>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Register;
