import React from 'react';
import SectionTitle from '../sectionTitle/SectionTitle';
import TestimonialOne from '../testimonial/TestimonialOne';

const AboutLittleHouseOfHugs = () => {
    return (
        <div className="eduvibe-testimonial-one edu-testimonial-area edu-section-gap bg-image">
            <div className="container eduvibe-animated-shape">
                <div className="row">
                    <div className="col-lg-12">
                        <SectionTitle
                            classes = "text-center"
                            slogan = "About"
                            title = "Little House of Hugs"
                        />
                    </div>
                </div>
                <div className="row g-5 mt--25">
                    <div className="col-lg-6 col-12">
                        <div className="satisfied-learner">
                            <div className="thumbnail">
                                <img className="image-1" src="/images/littlehouseofhugs/about/amber-bella-1.png" alt="Amber and Bella Lorenzini" />
                            </div>
                            {/* <div className="trophy-content bounce-slide">
                                <div className="icon">
                                <img src="/images/about/about-05/book.png" alt="Icons Images"/>
                                </div>
                                <div className="content">
                                    <h6 className="text">50K+</h6>
                                    <span>Satisfied Learners</span>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <TestimonialOne item="3" />
                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-13.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-13-01.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-04.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-4">
                        <img src="/images/shapes/shape-02-01.png" alt="Shape Thumb" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutLittleHouseOfHugs;